import React, { ChangeEvent, useCallback, useState } from 'react';
import { AxiosError } from 'axios';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { t } from '../../../i18n';
import { subscribeBlogApi } from '../../../utils/api';
import { OopsPanel } from '../OopsPanel';
import { SubscribeSuccess } from '../SubscribeSuccess';
import { TextInputWithIcon } from '../TextInputWithIcon';
import styles from './styles.module.scss';
import { Button } from '../../main/Button';
import { getFromUrl } from '../../../utils/getFromUrl';

const SubscriptionForm = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const formName = 'order_form_subscription_blog';

  const path = getFromUrl();
  const [inputsState, setInputsState] = useState<Record<string, string>>({});
  const [errors, setErrors] = useState<Record<string, boolean>>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [showOops, setShowOops] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { email } = inputsState;

  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name } = e.target;
    const { value } = e.target;

    setInputsState((prevState) => ({ ...prevState, [name]: value }));
  };

  const validateFields = useCallback(() => {
    // eslint-disable-next-line no-shadow
    const errors = {
      email: !email || email.length <= 2,
    };

    setErrors(errors);

    return !Object.values(errors).some((val) => val);
  }, [email, setErrors]);

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      if (!validateFields()) return;

      setIsLoading(true);

      try {
        if (!executeRecaptcha) return;
        const captcha = await executeRecaptcha(formName);

        const res = await subscribeBlogApi({ ...inputsState, captcha });

        if (res) {
          setShowSuccess(true);
          setInputsState({});
        }
      } catch (e) {
        const error = e as AxiosError;
        setErrorMessage(error.response?.data.error);
        setShowOops(true);
      } finally {
        setIsLoading(false);
      }
    },
    [inputsState]
  );

  return (
    <>
      <form
        id={formName}
        onSubmit={handleSubmit}
        className={styles.subscribe_wrap}
      >
        <p className={styles.subscribe_text}>{t('blog.subscribe_text')}</p>
        <TextInputWithIcon
          id={`${path}-email`}
          name="email"
          value={inputsState.email ?? ''}
          handler={onChange}
          label="cases.blog.email"
          icon="/case/restocare/contact_email.svg"
          hasError={errors.email}
          isSuccess={false}
        />
        <div className={styles.subscribe_bottom}>
          <Button
            className={styles.subscribe_button}
            isLoading={isLoading}
            disabled={isLoading}
          >
            {t('blog.subscribe')}
          </Button>
        </div>
      </form>

      <SubscribeSuccess isOpen={showSuccess} />
      <OopsPanel isOpen={showOops} errorMessage={errorMessage} />
    </>
  );
};

export default SubscriptionForm;
