import React, { FC } from 'react';
import styles from './styles.module.scss';
import { t } from '../../../i18n';

type PropsType = {
  isOpen: boolean;
};

const SubscribeSuccess: FC<PropsType> = ({ isOpen }) =>
  isOpen ? (
    <div className={styles.success}>
      <span>{t('blog.success')}</span>
    </div>
  ) : null;

export { SubscribeSuccess };
